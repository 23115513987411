.switchButtonBox{
    position:absolute;
    right:40px;
    top:12px;
    z-index: 500;
}
.restaurantName{
    position: absolute;
    left: 95px;
    top:14px;
    z-index: 500;
    justify-content: center;
    
}
.restaurantName>span{
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}
@media(max-width:480px){
    .switchButtonBox{
        right: 15px;
    }
}