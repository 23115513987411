.deliveredOrdersDetails{
    height: 520px;
    width:'80%';
    background-color: #ffff;
    border-radius: 12px;  box-shadow: 0 6px 18px -9px rgba(0, 0, 0, 0.5);
    z-index: 100;
    overflow: hidden;
    padding-bottom: 25px;

}
.header {
    background-color: #f6f6f6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px 18px
}
.header>h2 {
    font-size: 20px;
    font-weight: 600;
}

.close {
    font-size: 20px;
    color: black;
    cursor: pointer;
}
.orderNo{
    width:'100%';
    margin: 10px;
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 8px;
}
.orderDetails{
    padding: 10px;
}
.orderNo h5{
    font-size: 18px;
    font-weight: 600;
}
.orderNoText{
    padding:10px 20px;
    width:'100%';
    padding-left: 25px;
}
.orderNoText h6{
    font-size: 16px;
    font-weight: 500;
}
.product{
    width:'100%';
    margin: 10px;
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 8px;
}
.product h5{
    font-size: 18px;
    font-weight: 600;
}
.productItem{
    padding:10px 20px;
    width:'100%';
    padding-left: 25px;
    display: flex;  
}
.productItem h6{
    font-size: 16px;
    font-weight: 500;
    margin:0px 10px
}
.productItemContainer{
    height: 80px;
    overflow-y: scroll;
}
.customerInfo{
    width:'100%';
    margin: 10px;
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 8px;
}
.customerInfo h5{
    font-size: 18px;
    font-weight: 600;
}
.customerData{
    padding:1px 20px;
    width:'100%';
    padding-left: 25px;
    height: 60px;
    overflow-y: auto;
}
.customerData h6{
    font-size: 16px;
    font-weight: 500;
    margin: 5px 0px;
}
.address{
    font-size: 14px;
    font-weight: 500;
    color: rgba(0,0,0,0.6);
}