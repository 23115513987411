.updateProducts{
    height:450px ;
    width:45%;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 6px 18px -9px rgba(0, 0, 0, 0.5);
    padding:20px;
}
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    border-bottom: 1px solid lightgray;
}
.header>h5{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 6px;
}
.updateProduct_body{
    padding: 25px 0px;
    margin-top: 20px;
}
.updateProducts_inputs{
    margin-top: 20px;
}
.updateProducts_inputs>h6{
    font-size: 15px;
    font-weight: 700;
}
.UpdateProducts_inputBox{
    border: 1px solid #000;
    margin: 5px 0px; 
    padding: 18px;
    border-radius: 7px;
    width: 90%;
}
.UpdateProducts_inputBox>input{
    width: 100%;
    border: none;
    outline: none;
}
.UpdateProducts_inputBox:focus-within{
    border: 1px solid #ff7378;
}
.updateProducts_button{
    display: flex;
    justify-content: center;
    margin: 35px 0px;
}

@media(max-width:480px){
    .updateProducts{
        width:55%;
        padding-right: 25px;
       /* min-width:350px */
    }
    .UpdateProducts_inputBox{
        width:90%
    }
}
@media(max-width:750px){
    .updateProducts{
        padding-right: 35px;
        width:60%
    }
    .updateProducts_button>button{
        width:150px
    }
}