.splash {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.splash img{
    width:70px;
    object-fit: contain;
    margin-bottom: 15px ;
}