.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;

}

.login__container {
    width: 90%;
    height: 500px;
    background-color: white;
    box-shadow: 0 6px 18px -9px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    top: 0;
    display: flex;
    /* margin-top: 20px; */
    flex-direction: column;
    align-items: center;
    margin-bottom: auto;
    margin-top: auto;
    /* justify-content: center; */
}

.login__circleContainer {
    width: 10%;
    -ms-transform: rotate(-55deg);
    transform: rotate(-55deg);
    margin-left: 40px;


}

.circle__1 {
    height: 100px;
    width: 100px;
    border-radius: 100%;
    background-color: #ff7378
}

.circle__2 {
    height: 50px;
    width: 50px;
    border-radius: 100%;
    background-color: #ff7378;
    margin-top: 10px;
}

.circle__curve {
    width: 300px;
    height: 100px;
    background-color: #ff7378;
    border-radius: 0px 0px 0px 100px
}

.login__circles {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
}

.login__authContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    z-index: 1;
    margin-top: auto;
    margin-bottom: auto;

}

.image {
    width: 70px;
    object-fit: contain;
}

.input {
    display: flex;
    border: 1px solid lightgray;
    border-radius: 5px;
    align-items: center;
    justify-content: space-between;
    width: 270px;
    margin: 10px 0px;
    overflow: hidden;
    background-color: rgb(249, 249, 249);
    height: 50px;
    box-sizing: border-box;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    /* background-color: red; */
}
.input:focus-within{
    border:1.5px solid #ff7378
}

.input input {
    border: none;
    outline: none;
    padding: 0px 25px;
    background-color: rgb(249, 249, 249);
    /* width:90% */
}

.icon {
    background-color: rgb(249, 249, 249);
    height: 100%;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.9);
    box-sizing: border-box;
    border-left: 1px solid lightgray;
    padding: 0px;
}

.login__authContainer h3 {
    font-size: 19px;
    font-weight: 500;
    margin: 13px 0px;
}

.LoginButton {
    padding: 13px;
    background-color: #ff7378;
    width: 270px;
    border-radius: 7px;
    outline: none;
    border: none;
    cursor: pointer;
    color: #fff;
    margin: 13px 0px;
}

.LoginButton:hover {
    background-color:white;
    color: #ff7378;
    border: 1px solid #ff7378;
}

.bottom__circlesContainer {
    position: relative;
    top: 0;
    background-color: #ff7378;
    width: 100px;
    height: 20%;
}

.circleContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: aqua;
}
.eyeButton {
    position: absolute;
    right: 47px
}

@media(max-width:680px) {
    .circle__curve{
        max-width: 220px;
        height: 70px;
        position: absolute;
        right: 0;
    }
  .login__circleContainer{
    display: none;
  }
  .input{
    max-width:220px ;
  }
  .LoginButton{
     max-width:220px ;
  }
  .eyeButton{
    right:10px;
  }

}
/*  */

input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}