.filterDate{
    display: flex;
    min-width: 30vw;
    box-shadow: 0 1px 12px -9px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    background-color: white;
    align-items: center;
    padding: 8px;
    border: 1px solid lightgray;

}
.filterDate>button{
    background-color: #ff7378;
    color: white;
    padding: 5px 10px;
    margin-left: 1px;

}
.filterDate>button:hover{
    background-color: white;
    border: 1px solid #ff7378;
    color: #ff7378;
}
.filterDatesText{
    margin-left: 40px;
   
}
.filterDatesText span{
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    margin: 0px 3px;
}
.DateRangeContainer{
    display: flex;
    flex-direction: column;
    position: absolute;
    right:120px;
    top:115px;
    background-color: white;
    z-index: 50;
    box-shadow: 0 1px 12px -9px rgba(0, 0, 0, 0.5);    }
   .cancel{
    /* height: 50px; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 5px;
    cursor: pointer;
   }