.modalContainer {
    height: 550px;
    min-width: 80vw;
    background-color: #ffff;
    border-radius: 12px;  box-shadow: 0 6px 18px -9px rgba(0, 0, 0, 0.5);
  
    z-index: 100;
    top: 0px;
    left: 0px;
    overflow: hidden;
}

.header {
    background-color: #f6f6f6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px 18px
}

.header>h2 {
    font-size: 20px;
    font-weight: 600;
}

.close {
    font-size: 20px;
    color: black;
    cursor: pointer;
}

.collapseHeader {
    background-color: #f6f6f6;
    display: flex;
    justify-content: space-between;
    padding: 10px 18px;
    margin: 10px;
    border-radius: 5px;
    align-items: center;
}

.collapseContainer {
    display: flex;
    flex-direction: column;

}

.collapseHeader>h3 {
    font-size: 17px;
    font-weight: 500;
}

.expandIcon {
    cursor: pointer;
}

.collapseData {
    background-color: white;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 0.05fr));
    padding: 5px 35px;
}

.collapseData h5 {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.4;
    width: 600px;
    padding: 12px 0px;

}

.collapseIcon {
    font-size: 16px;
    padding: 8px 0px;

}

.infoContainer {
    height: 400px;
    overflow-y: auto;
}

.productData {
    padding: 0px 20px;
}

.dataHeaderMain {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
}

.dataHeaderMain h5 {
    font-size: 17px;
    font-weight: 600;
}

.dataHeader {
    width: 35%;
    display: flex;
    justify-content: space-between;
}

.dataHeader1 {
    display: flex;
    width: 40%;
    justify-content: space-between;
}

.productItemMain {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    border-bottom: 1px solid #f9f9f9;
    cursor: pointer;
}

.productItemMain h6 {
    font-size: 15px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);


}

.productItem {
    width: 35%;
    display: flex;
    justify-content: space-between;
    gap: 10px
}

.productItem>li {
    font-size: 14px;
}

.productItem>h6 {
    font-size: 14px;
}

.productItem1 {
    display: flex;
    justify-content: space-between;
    width: 40%;
}

.productItem1>h6 {
    width: 80px;
    text-align: center;
}

.bottomContainer {}

@media (max-width:1000px) {
    .collapseData h5 {
        font-size: 16px;
        width: 200px;
    }

    .collapseData {
        column-gap: 10%;
    }

    .productItem1 {
        width: 60%
    }

    .productItem1>h6 {
        font-size: 14px;
    }

    .dataHeader1 {
        width: 60%
    }

    .dataHeaderMain>h5 {
        font-size: 14px;
    }

    .dataHeader1>h5 {
        font-size: 14px;
        font-weight: 400;
    }

    .productItemMain>li {
        font-size: 14px;
    }

    .orderTotalH5 {
        font-size: 13px;
        color: red;
    }

    .collapseData h5 {
        font-size: 14px;
    }

    .dataHeader>h5 {
        font-size: 14px;
        font-weight: 400;
    }
}

@media(max-width:450px) {
    .dataHeaderMain {
        min-width: 400px
    }

    .productItemMain {
        min-width: 400px
    }
}

.orderTotal {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 20px;
    margin-top: 20px;
    column-gap: 67%;
}

.orderTotal h5 {
    font-size: 17px;
    font-weight: 600;
    padding: 8px 0px;
    width: 200px;
    color: rgba(0, 0, 0, 0.9);
}

.bottomContainer {
    height: 100%;

}

.buttonContainer {
    height: 130px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 50px;
    background-color: #f6f6f6;
    border: 0.3px solid #f5f5f5;
}

.bottomButton {
    padding: 15px 33px;
    background-color: #ff7378;
    color: white;
    cursor: pointer;
    margin-bottom: 40px;
    border: none;
    outline-width: none;
    border-radius: 25px;
    box-shadow: 0 6px 18px -9px rgba(0, 0, 0, 0.5);
    transition: transform 150ms cubic-bezier(0.25, 0.46, 0.46, 0.46);
    font-weight: bold;
    border: 0px solid lightgrey
}

.bottomButton:hover {
    transform: scale(1.07);
}
.bottomButton1{
    background-color: "#f6f6f6";
     border: "0.5px solid lightgrey";color: "black"
}

.bottomButton:disabled:hover {
    transition: none;
    transform: none;
}
.bottomButton:disabled{
    background-color: rgba(255, 115, 120, 0.58);
}

.cancelButton {
    background-color: #f6f6f6;
    color: black;
    box-shadow: 0 6px 18px -9px rgba(0, 0, 0, 0.5);
    border: 0.5px solid lightgrey;
    box-sizing: border-box;
}

.cancelButton:hover {
    background-color: black;
    color: white;
}


.cancelModal {
    height: 100%;
    width: 80vw;
    position: absolute;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media(max-width:480px){
    .buttonContainer{
        /* background-color: red; */
        width:100%;
        padding: 0px 0px;
    }
    .bottomButton{
        padding:10px 15px;
        border-radius: 10px;
    }
    .cancelModal{
        left:40px
    }
}