.trackingOrders{
    height: 100vh;
    width:100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.orderTracking {
    width: 100vw;
    background-color: #f6f6f6;
    height: 100vh;
    display: flex;
}

.sidebar {
    width: 4%;
}

.trackingCard {
    width: 96%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media(max-width:1100px) and (min-width:480px) {
   .trackingCard{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 25px;
   }
}

@media(max-width:480px) {
  
    .orderTracking {
        overflow-x: scroll;
        margin-right: 0px;
         
    }

    .trackingCard {
        min-width: 600px;
        overflow: scroll;
    }

}