.cancelModal {
    width: 70%;
    background-color: #f6f6f6;
    z-index: 200;
    box-shadow: 0 6px 18px -3px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.header {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* position: relative; */
    /* top: 0; */
    padding: 8px;
    border-bottom: 1px solid lightgrey;

}

.header>h5 {
    font-size: 20px;
    font-weight: 500;
    margin-left: 20px;
}

.closeIcon {
    margin-top: 2px;
    cursor: pointer;
}

.reasonContainer {
    height: 280px;
    padding: 10px 25px;
    overflow-y: scroll;
}

.selectReason {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 18px 0px;
    border-bottom: 1px solid lightgrey;
}

.otherReason {
    display: flex;
    flex-direction: column;
    margin: 10px 0px
}

.otherReason>textarea {
    margin-top: 8px;
    outline: none;
    padding: 6px;
}

.selectReason>h5 {
    font-size: 16px;
    font-weight: 600;
}

.otherReason>h5 {
    /* font-size: 16px; */
    font-weight: 500;
}

.buttonContainer {
    height: 83px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonContainer>button {
    padding: 15px 30px;
    background-color: #ff7378;
    outline-width: none;
    border: none;
    border-radius: 25px;
    color: white;
    cursor: pointer;
    width: 50%;
}

.buttonContainer>button:disabled {
    background-color: rgba(255, 115, 120, 0.73);

}
@media(max-width:480px){
    .cancelModal{
        width:100%
    }
}