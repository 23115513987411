.menuScreen{
    min-height: 100vh;
    width:100%;
    display: grid;
    place-items: center;
    display: flex;
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    /* overflow-x: hidden; */
}
.menuScreen_products{
    position: absolute;
    width:calc(100% - 100px);
    left:80px
}
@media(max-width:480px){
   
    .menuScreen_products{
        width: 100%;
        left: 0px;
    }
}