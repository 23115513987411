@import url("https://fonts.googleapis.com/css2?family=Work+Sans&display=swap");

.trackingCard {
  background-color: white;
  min-width: 90vw;
  /* height: 570px; */
  border-radius: 10px;
  box-shadow: 0 6px 18px -9px rgba(0, 0, 0, 0.5);
  margin-top: auto;
  margin-bottom: auto;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 14px;
  border-bottom: 1px solid lightgray;
  /* background-color: aqua; */
}

.cardHeaderStatus {
  display: flex;
  width: 100%;
  align-items: center;
}

.cardHeaderStatus h2 {
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  font-family: "Work Sans", sans-serif;
  margin-left: 13px;
  display: block;
}

.upcoming {
  display: flex;
  align-items: center;
  padding: 20px 10px 20px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 15px;
  border: none;
  background-color: white;
  width: 50%;
}

.upcoming:hover {
  background-color: #f5f5f5;
}

.borderColor {
  color: #ff7378;
}

.border {
  border: 2px solid #ff7378;
}

.count {
  border: 1px solid lightgray;
  padding: 14px 20px 14px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  transition: transform 150ms cubic-bezier(0.25, 0.46, 0.46, 0.46);
  margin-left: 10px;
}

.count:hover {
  transform: scale(1.05);
}

.count h3 {
  font-size: 20px;
  font-weight: 400;
  color: mediumseagreen;
}

.refresh {
  color: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-top: 5px;
}

.refreshIcon:hover {
  color: black;
  cursor: pointer;
}

.select {
  display: flex;
  width: 20vw;
}

.showTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 170px;
}

.showTitle > h5 {
  font-size: 30px;
  color: lightgray;
  font-weight: 400;
}

.subHeader {
  height: 70px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid lightgray;
  background-color: #f1f1f1;
}

.subHeader > h2 {
  font-size: 18px;
  font-weight: 500;
  margin-left: 20px;
  margin-right: 20px;
}

.itemsDataContainer {
  height: 300px;
  overflow-y: auto;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid lightgray;
  padding: 15px;
  padding-left: 40px;
}

.item:hover {
  background-color: #f5f5f5;
}

.item > span {
  width: 150px;
  font-size: 16px;
  font-weight: 400;
}

.pagination {
  display: flex;
  /* background-color: #ff7378; */
  position: relative;
  top: 0;
}

.paginationBox {
  margin-left: auto;
  display: flex;
  padding: 15px;
  margin-top: 30px;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.back {
  height: 35px;
  border: 1px solid lightgray;
  background-color: #f6f6f6;
  width: 35px;
  border-radius: 8px;
  display: grid;
  place-items: center;
  cursor: pointer;
  margin-right: 5px;
}

.status {
  width: 35px;
  border: 1px solid lightgray;
  height: 35px;
  border-radius: 8px;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.next {
  height: 35px;
  border: 1px solid lightgray;
  background-color: #f6f6f6;
  width: 35px;
  border-radius: 8px;
  display: grid;
  place-items: center;
  cursor: pointer;
  margin-left: 5px;
}

.modal {
  height: 100vh;
  width: 100%;
  /* background-color: #000; */
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newModal {
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.75;
}

.loading {
  position: absolute;
  top: 55%;
  left: 52vw;
  z-index: 20;
}

@media (max-width: 1100px) {
  .cardHeaderStatus h2 {
    font-size: 16px;
  }

  .count {
    height: 20px;
  }

  .refresh {
    padding: 0px;
  }

  .upcoming {
    padding: 10px 2px;
  }
}

@media (max-width: 800px) {
  .cardHeaderStatus {
    width: 80%;
    /* background-color: #f6f6f6; */
    overflow: scroll;
  }

  .select {
    width: 200px;
    margin: 0px;
  }

  .subHeader {
    overflow-x: auto;
  }

  .subHeader > h2 {
    font-size: 16px;
    margin-left: 15px;
    margin-right: 10px;
  }
}
@media (max-height: 610px) {
  .trackingCard {
    background-color: white;
    min-width: 90vw;
    /* height: 570px; */
    border-radius: 10px;
    box-shadow: 0 6px 18px -9px rgba(0, 0, 0, 0.5);
    height: 86%;
    min-height: 500px;
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
    top: 0;
  }
}
