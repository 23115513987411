.flavourDetailModal {
    height: 440px;
    width: 70%;
    background-color: white;
    border-radius: 10px;
    z-index: 200;
    box-shadow: 0 6px 18px -3px rgba(0, 0, 0, 0.5);
}
.header{
    display: flex;
    justify-content: space-between;
    padding: 12px 16px ;
    border-bottom: 1px solid lightgray;
}
.header>h5{
    font-size: 19px;
    font-weight: 600;
    margin-top: 5px;
}
.flavourItem{
    padding: 15px 20px;
}
.flavourItem h5{
  font-size: 17px;
  font-weight:600 ;
}
.flavourItem span{
    font-size: 15px;
}